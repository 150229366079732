import { apiSlice } from "./apiSlice"

const extendedApi = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		getUpdates: build.query({
			query: () => ({url: '/updates', method: 'get'})
		}),
		getUpdate: build.query({
			query: id => ({url: '/updates/' + id, method: 'get'}),
		}),
	}),
	overrideExisting: false,
})

export const {
	useGetUpdatesQuery,
	useGetUpdateQuery,
	util: { getRunningQueriesThunk }
} = extendedApi

export const {
	getUpdate,
	getUpdates
} = extendedApi.endpoints
