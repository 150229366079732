import React, {useState} from 'react';
import Layout from "../components/Layout";
import {wrapper} from "../store";
import {getRunningQueriesThunk, getUpdates, useGetUpdatesQuery} from "../api/updates";
import Link from "next/link";
import {NextSeo} from "next-seo";

export const getServerSideProps = wrapper.getServerSideProps(store => async ({req, params}) => {

	store.dispatch(getUpdates.initiate())
	await Promise.all(store.dispatch(getRunningQueriesThunk()));

	return {
		props: {},
	};
});


function Index() {
	const [showPagination, setShowPagination] = useState(false);
	const { data:updates } = useGetUpdatesQuery()
	const [date, setDate] = useState('');
	
	function createMarkup(data) {
		return {__html: data};
	}
	
	function getDate(date) {
		return new Date(date).toLocaleDateString('en-us', {  year:"numeric", month:"long", day:"numeric"})
	}
	
	return (
		<Layout>
			<div className="mx-auto flex flex-wrap py-6 bg-gray-100 pb-40">
				<div className="mx-auto container">
					<section className="w-full md:w-2/3 flex flex-col items-center px-3">
						
						{updates.data.map((update, index) =>
							<article className="flex flex-col shadow my-4 w-full" key={index}>
								{/*<a href="#" className="hover:opacity-75">*/}
								{/*	<img src="https://source.unsplash.com/collection/1346951/1000x500?sig=1" />*/}
								{/*</a>*/}
								<div className="bg-white flex flex-col justify-start p-6">
									{/*<a href="#" className="text-blue-700 text-sm font-bold uppercase pb-4">Technology</a>*/}
									<Link href={update.full_url} className="text-3xl font-bold hover:text-gray-700 pb-4">{update.title}</Link>
									<p className="text-sm pb-3">
										Published on <span suppressHydrationWarning className="text-sm text-gray-500">{getDate(update.created_at)}</span>
									</p>
									<div suppressHydrationWarning className="pb-6 w-full" dangerouslySetInnerHTML={createMarkup(update.body)} />
									{/*<a href="#" className="pb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis porta dui. Ut eu iaculis massa.*/}
									{/*	Sed ornare ligula lacus, quis iaculis dui porta volutpat. In sit amet posuere magna..</a>*/}
									{/*<a href="#" className="uppercase text-gray-800 hover:text-black">Continue Reading <i className="fas fa-arrow-right"></i></a>*/}
								</div>
							</article>
						)}
						
					
					</section>
					
					<aside className="w-full md:w-1/3 flex flex-col items-center px-3">
					
					
					</aside>
				</div>
			</div>
		</Layout>
	);
}

export default Index;
